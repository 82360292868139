import { request } from './axios'
import { AxiosResponse } from 'axios'
import { TCoveredEntities, TCoveredEntity, TCoveredEntityProcess } from '../type'

export const CoveredEntity = {
  get: async (search_key = '', page_number = '1', all = false, useAllowedCeList = false): Promise<TCoveredEntities> => {
    const key = useAllowedCeList ? 'allowed_ce_list' : 'search_key'
    const {
      data: { covered_entities, max_page_size, page_no, total_count, total_pages },
    } = await request({
      url: `/metadata/ce/?page_number=${page_number}&${key}=${search_key}`,
    })

    const coveredEntities: TCoveredEntity[] = []
    covered_entities && coveredEntities.push(...covered_entities.value)
    if (all) {
      const promises = []
      for (let nextPage = page_no + 1; nextPage <= total_pages; nextPage++) {
        promises.push(
          request({
            url: `/metadata/ce/?page_number=${nextPage}&${key}=${search_key}`,
          }).then((response) => {
            if (response.data.covered_entities) {
              return response.data.covered_entities.value
            }
            return []
          })
        )
      }
      const results = await Promise.all(promises)
      results.forEach((entities) => {
        coveredEntities.push(...entities)
      })
    }
    return {
      covered_entities: { value: coveredEntities },
      max_page_size,
      page_no,
      total_count,
      total_pages,
    }
  },

  add: (covered_entity: TCoveredEntityProcess): Promise<AxiosResponse> =>
    request({
      method: 'post',
      url: '/metadata/ce/',
      data: {
        ...covered_entity,
      },
    }),

  edit: (covered_entity: TCoveredEntityProcess): Promise<AxiosResponse> =>
    request({
      method: 'put',
      url: '/metadata/ce/',
      data: {
        ...covered_entity,
      },
    }),
}
