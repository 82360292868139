import { request } from './axios'
import { TDraft as IDraft, Drafts, TDraftSave } from '../type/'
import { toUTC } from '../utility/date-format'

export const Draft = {
  get: async (page_number = '1'): Promise<Drafts> => {
    const {
      data: { results, max_page_size, page_no, total_count, total_pages },
    } = await request({
      url: `/config/drafts/?page_number=${page_number}`,
    })

    const drafts = results.map((draft: IDraft) => {
      draft.effective_date = toUTC(draft.effective_date)
      return draft
    })

    return {
      drafts,
      max_page_size,
      page_no,
      total_count,
      total_pages,
    }
  },

  create: async (draft: TDraftSave) => {
    await request({
      url: '/config/drafts/',
      method: 'post',
      data: {
        ...draft,
      },
    })
  },

  update: async (draft: TDraftSave) => {
    const localDraft: { name?: string } = { ...draft }
    delete localDraft.name
    await request({
      url: `/config/drafts/${draft.name}`,
      method: 'put',
      data: {
        ...localDraft,
      },
    })
  },

  delete: async (name: string) => {
    await request({
      url: `/config/drafts/${name}`,
      method: 'delete',
    })
  },
}
