import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import variable from '../../scss/variables.scss'

interface IProps {
  isShow: boolean
  size?: DialogProps['maxWidth']
  title: React.ReactNode | string
  content?: React.ReactNode
  actions?: React.ReactNode
  highlightedActions?: boolean
  onClose: () => void
  testPrefix: string
  isDivider?: boolean
}

export const IQDialog = ({
  isShow,
  size = 'sm',
  title,
  content,
  actions,
  onClose,
  testPrefix,
  highlightedActions = false,
  isDivider = false,
}: IProps) => (
  <Dialog
    open={isShow}
    fullWidth
    disableEscapeKeyDown={true}
    maxWidth={size}
    onClose={onClose}
  >
    <>
      <DialogTitle
        sx={{
          pr: 6,
        }}
        data-test={`${testPrefix}-title`}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          mr: 1,
          mt: 1,
        }}
        data-test={`${testPrefix}-close`}
      >
        <Close />
      </IconButton>
      {isDivider && <Divider sx={{ mr: 3, ml: 3, mb: 2 }} />}
    </>
    {content ? <DialogContent sx={{ pt: 0 }}>{content}</DialogContent> : <></>}
    <DialogActions sx={{ px: 2, py: 2, backgroundColor: highlightedActions ? variable.base200 : '' }}>{actions}</DialogActions>
  </Dialog>
)
