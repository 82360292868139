import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios'
import { store } from '../store'
import { appFetch } from '../store/reducer/app'
import { toast } from 'react-toastify'

const { REACT_APP_BACKEND_API_URL, REACT_APP_LOCAL_API_URL } = process.env

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  showToast?: boolean
}

const request: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? REACT_APP_LOCAL_API_URL : REACT_APP_BACKEND_API_URL,
})

const onRequest = (config: CustomAxiosRequestConfig): CustomAxiosRequestConfig => {
  if (config.url !== '/auth/token/') {
    const {
      app: { jwt },
    } = store.getState()
    config.headers = {
      ...(config.headers as RawAxiosRequestHeaders),
      Authorization: `token ${jwt}`,
    } as AxiosRequestHeaders
  }
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error({ ...error })
  return Promise.reject({ ...error })
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const {
    data: { message },
  } = response
  toast(message, {
    type: 'success',
    position: 'top-center',
  })
  return response
}

let customOnResponseError: ((error: AxiosError) => Promise<any>) | null = null

const setCustomOnResponseError = (handler: (error: AxiosError) => Promise<any>) => {
  customOnResponseError = handler
}

const resetCustomOnResponseError = () => {
  customOnResponseError = null
}

const onResponseError = async (error: AxiosError): Promise<any> => {
  if (customOnResponseError) {
    await customOnResponseError(error)
    resetCustomOnResponseError()
    return Promise.reject(error)
  }

  const { response }: AxiosError = { ...error }
  if (response && response.data) {
    const errorMessage = (response.data as { 'Error Message'?: { details: string; error_code: string } })['Error Message']
    const { status } = response

    if (status === 401 || status === 403) {
      if (errorMessage && errorMessage.error_code === 'ER-401-2') {
        await store.dispatch(appFetch())
      }
    } else if (errorMessage) {
      if ((response.config as CustomAxiosRequestConfig).showToast !== false) {
        toast(JSON.stringify(errorMessage.details), {
          type: 'error',
          position: 'top-center',
        })
      }
    }
  }
  console.error({ ...error })
  return Promise.reject({ ...error })
}

const createInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}

createInterceptors(request)

export { request, setCustomOnResponseError, resetCustomOnResponseError }
